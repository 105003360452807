let configApp;

export function setupJson(json) {
  configApp = json;
}

export function getYears() {
  return configApp.view
    .map(e => e.year);
}

export function getTermsForYear(year) {
  return configApp.view
    .find(e => searchString(e.year) == searchString(year))
    .term
    .map(e => e.name);
}

export function getWeeksForYearAndTerm(year, term) {
  return configApp.view
    .find(e => searchString(e.year) == searchString(year))
    .term.find(e => searchString(e.name) == searchString(term))
    .week
    .map(e => e.name);
}

export function getGroupsForYearAndTermAndWeek(year, term, week) {
  return configApp.view
    .find(e => searchString(e.year) == searchString(year))
    .term.find(e => searchString(e.name) == searchString(term))
    .week.find(e => searchString(e.name) == searchString(week))
    .group
    .map(e => e.name);
}

export function getWords(year, term, week, group) {
  return configApp.view
    .find(e => searchString(e.year) == searchString(year))
    .term.find(e => searchString(e.name) == searchString(term))
    .week.find(e => searchString(e.name) == searchString(week))
    .group.find(e => searchString(e.name) == searchString(group))
    .words;
}

export function getYear(year) {
  return configApp.view
    .find(e => searchString(e.year) == searchString(year));
}

export function getTerm(year, term) {
  return configApp.view
    .find(e => searchString(e.year) == searchString(year))
    .term.find(e => searchString(e.name) == searchString(term));
}

export function getWeek(year, term, week) {
  return configApp.view
    .find(e => searchString(e.year) == searchString(year))
    .term.find(e => searchString(e.name) == searchString(term))
    .week.find(e => searchString(e.name) == searchString(week));
}

export function getGroup(year, term, week, group) {
  return configApp.view
    .find(e => searchString(e.year) == searchString(year))
    .term.find(e => searchString(e.name) == searchString(term))
    .week.find(e => searchString(e.name) == searchString(week))
    .group.find(e => searchString(e.name) == searchString(group));
}

export function searchString(initial) {
  return initial
    .toString()
    .replaceAll(' ', '')
    .toLowerCase()
}

export function shuffleArray(array) {
  let shuffle = [...array];
  var currentIndex = shuffle.length, randomIndex;
  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [shuffle[currentIndex], shuffle[randomIndex]] = [
      shuffle[randomIndex], shuffle[currentIndex]];
  }
  return shuffle;
}