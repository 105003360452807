import React from 'react';
import { getWeeksForYearAndTerm } from '../helper/misc.helpers';
import { withRouter } from 'react-router-dom';
import { searchString } from '../helper/misc.helpers';

export class WeekComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      weeks: [],
    }
  }

  componentWillMount() {
    let yearId = this.props.match.params.yearId;
    let termId = this.props.match.params.termId;
    let weeks = getWeeksForYearAndTerm(yearId, termId);
    this.setState({
      weeks: weeks,
    })
  }

  onItemClick(week) {
    let yearId = this.props.match.params.yearId;
    let termId = this.props.match.params.termId;
    this.props.history.push(`/year/${yearId}/term/${termId}/week/${searchString(week)}`);
  }

  render() {
    return (
      <div className="button-container">
        {this.state.weeks.map((e, index) =>
          <div className="button" key={index} onClick={() => this.onItemClick(e)}>
            {e}
          </div>)}
      </div>
    )
  }
}

export default withRouter(WeekComponent);