import React from 'react';
import { getTermsForYear } from '../helper/misc.helpers';
import { withRouter } from 'react-router-dom';
import { searchString } from '../helper/misc.helpers';

export class TermComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      terms: [],
    }
  }

  componentWillMount() {
    let yearId = this.props.match.params.yearId;
    let terms = getTermsForYear(yearId);
    this.setState({
      terms: terms,
    })
  }

  onItemClick(term) {
    let yearId = this.props.match.params.yearId;
    this.props.history.push(`/year/${yearId}/term/${searchString(term)}`);
  }

  render() {
    return (
      <div className="button-container">
        {this.state.terms.map((e, index) =>
          <div className="button" className="button" key={index} onClick={() => this.onItemClick(e)}>
            {e}
          </div>)}
      </div>
    )
  }
}

export default withRouter(TermComponent);