import React from 'react';
import { withRouter } from 'react-router-dom';
import { Switch, Route, Redirect } from 'react-router';
import YearComponent from './year.page';
import TermComponent from './term.page';
import WeekComponent from './week.page';
import GroupComponent from './group.page';
import WordsComponent from './words.page';
import { setupJson } from '../helper/misc.helpers';
import logo from '../ConfigApp/logo.png';
import ReactGA from "react-ga4";

const jsonUrl = `${window.location.origin}/config/configApp.json`;

export class MainComponent extends React.Component {
  currentUrl;
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
    }
  }

  componentDidUpdate() {
    let path = `${window.location.pathname}${window.location.search}${window.location.hash}`.replace('#/','');
    if (path != this.currentUrl) {
      this.currentUrl = path;
      ReactGA.set('page_path', path);
      ReactGA.send('pageview');
    }
  }

  componentDidMount() {
    ReactGA.initialize('G-W90PVT3G3C');
    fetch(jsonUrl)
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        setupJson(json);
        this.setState({
          isReady: true
        })
      })
  };

  onBackButtonClick() {
    this.props.history.goBack();
  }

  render() {
    return (
      <>
        {window.location.hash !== "/#" && <div className="back-button" onClick={() => this.onBackButtonClick()}>Back</div>}
        <div className="title">Bedford Prep School Spelling Helper</div>
        {this.state.isReady && <Switch>
          <Route path="/year/:yearId/term/:termId/week/:weekId/group/:groupId" component={WordsComponent} />
          <Route path="/year/:yearId/term/:termId/week/:weekId" component={GroupComponent} />
          <Route path="/year/:yearId/term/:termId" component={WeekComponent} />
          <Route path="/year/:yearId" component={TermComponent} />
          <Route path="/" component={YearComponent} />
          <Redirect to="/" />
        </Switch>}
        <img className="logo" src={logo} />
      </>
    )
  }
}

export default withRouter(MainComponent);