import React from 'react';
import { getGroupsForYearAndTermAndWeek } from '../helper/misc.helpers';
import { withRouter } from 'react-router-dom';
import { searchString } from '../helper/misc.helpers';

export class GroupComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      groups: [],
    }
  }

  componentWillMount() {
    let yearId = this.props.match.params.yearId;
    let termId = this.props.match.params.termId;
    let weekId = this.props.match.params.weekId;
    let groups = getGroupsForYearAndTermAndWeek(yearId, termId, weekId);
    this.setState({
      groups: groups,
    })
  }

  onItemClick(group) {
    let yearId = this.props.match.params.yearId;
    let termId = this.props.match.params.termId;
    let weekId = this.props.match.params.weekId;
    this.props.history.push(`/year/${yearId}/term/${termId}/week/${weekId}/group/${searchString(group)}`);
  }

  render() {
    return (
      <div className="button-container">
        {this.state.groups.map((e, index) =>
          <div className="button" key={index} onClick={() => this.onItemClick(e)}>
            {e}
          </div>)}
      </div>
    )
  }
}

export default withRouter(GroupComponent);