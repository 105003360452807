import React from 'react';
import { getYears } from '../helper/misc.helpers';
import { withRouter } from 'react-router-dom';
import { searchString } from '../helper/misc.helpers';

class YearComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      years: [],
    }
  }

  componentWillMount() {
    let years = getYears();
    this.setState({
      years: years,
    })
  }

  onItemClick(year) {
    this.props.history.push(`/year/${searchString(year)}`);
  }

  render() {
    return (
      <div className="button-container">
        {this.state.years.map((e, index) =>
          <div className="button" key={index} onClick={() => this.onItemClick(e)}>
            {e}
          </div>)}
      </div>
    )
  }
}

export default withRouter(YearComponent);