import React from 'react';
import MainComponent from './Pages/main.page';

import './App.css';

function App() {
  return (
    <div className="App">
      <React.Fragment>
        <MainComponent />
      </React.Fragment>
    </div>
  );
}

export default App;
