import React from 'react';
import { getWords, getYear, getTerm, getWeek, getGroup, shuffleArray } from '../helper/misc.helpers';
import { playGameSound } from '../ConfigApp/assets.service';
import audio from '../ConfigApp/audio.png';

import { withRouter } from 'react-router-dom';

const steps = {
  INTRO: "INTRO",
  GAME: "GAME",
  FINISH: "FINISH",
}

export class WordsComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      words: [],
      currentStep: steps.INTRO,
      wordIndex: 0,
      isRevealed: false,
      sortedWords: []
    }
  }

  componentWillMount() {
    let yearId = this.props.match.params.yearId;
    let termId = this.props.match.params.termId;
    let weekId = this.props.match.params.weekId;
    let groupId = this.props.match.params.groupId;

    let words = getWords(yearId, termId, weekId, groupId);
    this.setState({
      words: words,
      sortedWords: shuffleArray(words)
    })
  }

  calculateTitle() {
    let yearId = this.props.match.params.yearId;
    let termId = this.props.match.params.termId;
    let weekId = this.props.match.params.weekId;
    let groupId = this.props.match.params.groupId;

    let year = getYear(yearId);
    let term = getTerm(yearId, termId);
    let week = getWeek(yearId, termId, weekId);
    let group = getGroup(yearId, termId, weekId, groupId);

    return `${term.name} ${group.name} (${week.name})`
  }

  onBeginClick() {
    this.setState({
      wordIndex: 0,
      currentStep: steps.GAME,
    })
  }

  onPlayClick() {
    playGameSound(this.state.sortedWords[this.state.wordIndex]);
  }

  onWordClickPlay(e) {
    playGameSound(e);
  }

  onRevealClick() {
    this.setState({
      isRevealed: true,
    })
  }

  onNextClick() {
    if (this.state.words.length - 1 == this.state.wordIndex) {
      this.setState({
        wordIndex: 0,
        isRevealed: false,
        currentStep: steps.FINISH,
      })
    } else {
      this.setState({
        wordIndex: this.state.wordIndex + 1,
        isRevealed: false,
      })
    }
  }

  onPlayAgainClick() {
    this.setState({
      wordIndex: 0,
      isRevealed: false,
      currentStep: steps.INTRO,
    })
  }

  onExitClick() {
    this.props.history.push(`/`);
  }

  render() {
    return (
      <div className="words-container">
        {this.state.currentStep == steps.INTRO &&
          <>
            <h3 className="words-container-title">{this.calculateTitle()}</h3>
            <table className="words">
              <tr>
                <th className="words-title">Words</th>
              </tr>
              {this.state.words.map((e, index) =>
                <tr><td key={index} onClick={() => this.onWordClickPlay(e)}>{e}</td></tr>)}
            </table>
            <div className="begin-button" onClick={() => this.onBeginClick()}>Begin</div>
          </>}
        {this.state.currentStep == steps.GAME &&
          <div className="game-container">
            <div className="game-title-container">
              <div className="game-title current-word">Current word</div>
              <div className="game-title current-word-rank">{this.state.wordIndex + 1}/{this.state.sortedWords.length}</div>
            </div>
            <img onClick={() => this.onPlayClick()} className="game-audio" src={audio}></img>
            <div className="game-button reveal-button" onClick={() => this.onRevealClick()}>{this.state.isRevealed ? this.state.sortedWords[this.state.wordIndex] : "Reveal"}</div>
            <div className="game-button next-button" onClick={() => this.onNextClick()}>{this.state.words.length - 1 == this.state.wordIndex ? "Finish" : "Next"}</div>
          </div>}
        {this.state.currentStep == steps.FINISH &&
          <div className="game-container">
            <div className="game-title">You finished success!!!</div>
            <div className="game-button" onClick={() => this.onPlayAgainClick()}>Play Again</div>
            <div className="game-button" onClick={() => this.onExitClick()}>Exit</div>
          </div>}
      </div>
    )
  }
}

export default withRouter(WordsComponent);