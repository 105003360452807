const mp3Url = `${window.location.origin}/audio/`
var currentSound = undefined

export function playGameSound(name) {
  try {
    if (currentSound && currentSound.src) {
      currentSound.pause()
      currentSound.currentTime = 0

      currentSound.src = `${mp3Url}${name}.mp3`
      currentSound.play().catch((err) => {
        console.log(err)
      })
    } else {
      let sound = new Audio(`${mp3Url}${name}.mp3`)
      currentSound = sound
      currentSound.play().catch((err) => {
        console.log(err)
      })
    }
  } catch (error) {
    console.log(error)
  }
}

export function stopGameSound() {
  try {
    currentSound.pause()
    currentSound.currentTime = 0
  } catch (error) {
    console.log(error)
  }
}